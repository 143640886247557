import clsx from 'clsx';
import { throttle } from 'lodash-es';
import { CSSProperties, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { onMessage } from 'firebase/messaging';
// Webolucio imports
import { Icon, LoadOverlay } from '@webolucio/web';
// App imports
import useGeolocation from '../../../hooks/useGeolocation';
import Header from '../Header';
import Footer from '../Footer';
import BpnlButton from '../../presentationals/BpnlButton';
import { messaging } from '../../../app/firebaseConfig';
import { NotificationRow } from '../../containers/Notifications/NotificationsPanel';
// Local imports
import classes from './index.module.scss';

export default function MainTemplate() {
	const [contentHeight, setContentHeight] = useState<string | null>(null);

	const headerRef = useRef<HTMLElement>(null);
	const footerRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const handleResize = throttle(() => {
			if (!headerRef.current || !footerRef.current) {
				setContentHeight('0');
				return;
			}

			setContentHeight(`calc(100vh - ${headerRef.current.clientHeight + 1}px - ${footerRef.current.clientHeight}px)`);
		}, 300);

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useLayoutEffect(() => {
		if (!headerRef.current || !footerRef.current) {
			setContentHeight('0');
			return;
		}

		setContentHeight(`calc(100vh - ${headerRef.current.clientHeight + 1}px - ${footerRef.current.clientHeight}px)`);
	}, [contentHeight]);

	useGeolocation({ watch: true });

	onMessage(messaging, (payload) => {
		if (!payload.notification) {
			return;
		}
		enqueueSnackbar(
			<NotificationRow
				title={payload.notification.title ? payload.notification.title : null}
				body={payload.notification.body ? payload.notification.body : null}
				tmbLink={payload.notification.icon}
			/>,
			{
				key: 'notification',
				hideIconVariant: true,
				autoHideDuration: 5000,
				action: function (snackbarId) {
					return <BpnlButton onClick={() => closeSnackbar(snackbarId)} variant="link" title={<Icon name="close-small" />} />;
				},
			},
		);
		new Notification(payload.notification.title ? payload.notification.title : '', {
			body: payload.notification.body,
			icon: payload.notification.icon,
		});
	});

	return (
		<>
			<Header className={classes.header} ref={headerRef} />
			<main
				className={clsx(classes.content, 'content')}
				style={contentHeight !== null ? ({ '--content-height': contentHeight } as CSSProperties) : undefined}
			>
				<Outlet />
				<LoadOverlay classes={{ container: classes.overlay }} fixed />
			</main>
			<Footer className={classes.footer} ref={footerRef} />
		</>
	);
}
